/* TourPlanDetail.css */

.detail-page {
    max-width: 800px;
    margin: 2rem auto;
    padding: 1rem;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .detail-header {
    position: relative;
    background-color: #f5f5f5;
    padding: 2rem;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  
  .detail-header img {
    width: 100%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    object-fit: cover;
    height: 250px;
  }
  
  .detail-content {
    padding: 2rem;
  }
  
  .detail-title {
    margin-top: 1rem;
    font-size: 2rem;
    color: #333;
  }
  
  .detail-description {
    font-size: 1rem;
    color: #666;
    line-height: 1.6;
    margin-top: 1rem;
  }
  
  .detail-contact {
    font-weight: bold;
    margin-top: 2rem;
  }
  
  .pdf-download-link {
    margin-top: 2rem;
    display: inline-block;
    background: #007bff;
    color: #fff;
    padding: 10px 15px;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s ease;
  }
  
  .pdf-download-link:hover {
    background: #0056b3;
  }
  
  .back-button {
    display: inline-block;
    background-color: #007bff;
    color: #ffffff;
    padding: 10px 15px;
    border-radius: 5px;
    text-decoration: none;
    margin-bottom: 20px;
    transition: background-color 0.2s;
  }
  
  .back-button:hover {
    background-color: #0056b3;
  }

/* Add this to your common CSS file or respective page-specific CSS files */

.page-background {
    background-image: url('https://source.unsplash.com/random/1920x1080?trip');
    background-size: cover;
    background-position: inherit;
    height: 100vh; /* Adjust based on your layout */
    width: 100%;
  }

  .loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .loader {
    border: 6px solid #f3f3f3;
    border-top: 6px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  